charity.employee = {

    sendAccountSetupEmail: function()
    {
        $('body').on('click', '.send-account-setup-email-team-member', function () {
            var $this = $(this),
                url = $this.attr('href');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        charity.base.addFlash('success', 'Successfully sent an account setup email to ' + data.email + '.');
                        charity.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        charity.base.addFlash('success', 'Vendor has already set up their account');
                        charity.base.scrollTop("main");
                    }
                });
            return false;
        });
    },
}