charity.order = {

    createCsvReport: function () {
        $('#export-order-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var orderIds = $('#orders-index').attr('data-order-ids').split(',');
            if (orderIds.length === 0) {
                charity.base.addFlash("warning csv-report-flash", "You need to have some orders before requesting a CSV report.");
                charity.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/order_csvs',
                        data: {'order_ids': orderIds},
                        type: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            $('#charity-flashes').prepend('<div class="alert alert-success csv-report-flash" role="alert">Successfully created the CSV report. Please check your current email account for a link to the file.</div>')
                            charity.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            $('#charity-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">Unable to create the CSV report. Please try again.</div>');
                            charity.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },
}