window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery-ui-dist/jquery-ui");

require("moment-timezone");

require("mdbootstrap/bootstrap");
require("mdbootstrap/mdb");
require("mdbootstrap/modules/dropdown");

// require("charity/charity.viva-wallet");

require("charity/_charity");
require("charity/charity.base");
require("charity/charity.checkout");
require("charity/charity.customer");
require("charity/charity.order");
require("charity/charity.donation");
require("charity/charity.event");
require("charity/charity.employee");
require("charity/charity.onboarding");
require("charity/charity.ready");
