$(document).ready(function () {
  charity.base.mdbootstrap();
  charity.base.changeSearchLimitParam();

  charity.checkout.selectPaymentMethod();
  charity.checkout.updateCountryAlpha2();
  charity.checkout.validCheckout();
  charity.checkout.validSubscripionCheckout();
  charity.checkout.selectTipAmount();
  charity.checkout.selectTipPlan();
  charity.checkout.triggerGiftAid();
  charity.checkout.customCurrencySelect();
  charity.checkout.customLanguageSelect();

  charity.employee.sendAccountSetupEmail();

  charity.customer.createCsvReport();

  charity.event.createCsvReport();

  charity.order.createCsvReport();

  charity.onboarding.createCharity();
});
