charity.checkout = {
  listenAmountChanges: function () {
    $("body").on("keyup", "#order_gross", function () {
      var intentTimeout = null;

      if (intentTimeout != null) {
        clearTimeout(intentTimeout);
      }
      intentTimeout = setTimeout(function () {
        intentTimeout = null;

        charity.checkout.updatePaymentIntent();
      }, 2500);
    });
  },

  updatePaymentIntent: function () {
    var amount = $("#order_gross").val();
    if (amount !== undefined || amount !== "" || amount !== "0") {
      $.ajax({
        url: "/payments/update_intent",
        type: "POST",
        data: {
          amount: $("#order_gross").val(),
          stripe_payment_intent_id: $("#stripe_payment_intent_id").val(),
          charity_id: $("#charity_id").val(),
        },
        dataType: "json",
        success: function async(data) {},
      });
    }
  },

  updateElementAmount: function (planPrice) {
    console.log(planPrice);
  },

  submitSubscriptionForm: function () {
    let stripe = Stripe($('meta[name="stripe-key"]').attr("content"), {
      stripeAccount: $('meta[name="stripe-account-id"]').attr("content"),
    });
    let planPrice = $("#process_subscription #monthly_plan_price").val();
    let donationPlans = $('meta[name="donation-plans"]').attr("content");

    if (donationPlans === "enabled") {
      const options = {
        mode: "subscription",
        amount: parseInt(planPrice),
        appearance: {
          theme: "stripe",
        },
        currency: "gbp",
        paymentMethodOrder: ["apple_pay", "google_pay", "card"],
      };

      const elements = stripe.elements(options);

      const paymentElement = elements.create("payment");
      paymentElement.mount("#subscription-payment-element");
      const $form = document.getElementById("process_subscription");
      const $seralizeForm = $("#process_subscription");

      $("body").on("click", ".checkout-select-plan", function () {
        var planPrice = $(this).attr("data-value-singularize");
        var planId = $(this).attr("data-plan-id");
        var formattdPrice = $(this).attr("data-value-formatted");
        elements.update({ amount: parseInt(planPrice) });
        $("#process_subscription #plan_id").val(planId);
        $("#monthly-price span").text(formattdPrice);
        return false;
      });

      $form.addEventListener("submit", async (event) => {
        event.preventDefault();

        const planId = $("#process_subscription #plan_id").val();
        const email = $("#process_subscription #user_email").val();

        const { error: submitError } = await elements.submit();
        if (submitError) {
          handleError(submitError);
          return;
        }

        $.ajax({
          url: "/payments/create_subscription",
          type: "POST",
          data: { plan_id: planId, email: email },
          dataType: "json",
          success: function async(data) {
            const subscriptionClientSecret = data.client_secret;
            const subscriptionType = data.type;
            $.ajax({
              url: "/payments/confirm",
              type: "POST",
              data: $seralizeForm.serialize(),
              dataType: "json",
              success: function async(data) {
                charity.base.disableCloseTabPrompt();
                charity.checkout.confirmSubscriptionSubscription(
                  stripe,
                  subscriptionType,
                  subscriptionClientSecret,
                  elements,
                  data.charity_id
                );
              },
              error: function (xhr, evt, status) {
                var errors = $.parseJSON(xhr.responseText).errors;
                if (errors.length === 1) {
                  $(
                    '<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' +
                      window.checkoutMessages.unsuccessfulOrder +
                      " <b>" +
                      errors[0] +
                      "</b></div>"
                  ).insertBefore("#checkout-section");
                } else {
                  $(
                    '<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' +
                      window.checkoutMessages.unsuccessfulOrder +
                      " " +
                      window.checkoutMessages.requiredFields +
                      "<ul></ul></div>"
                  ).insertBefore("#checkout-section");
                  for (_i = 0, _len = errors.length; _i < _len; _i++) {
                    var value = errors[_i];
                    $("#checkout-validation-warning")
                      .find("ul")
                      .append("<li>" + value + "</li>");
                  }
                }
                charity.base.scrollTop("#checkout-wrapper");
                $("#complete-purchase-button").attr("disabled", false);
                $("#confirmation-modal").modal("hide");
              },
            });
          },
          error: function (xhr, evt, status) {
            var errors = $.parseJSON(xhr.responseText).errors;
            if (errors.length === 1) {
              $(
                '<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' +
                  window.checkoutMessages.unsuccessfulOrder +
                  " <b>" +
                  errors[0] +
                  "</b></div>"
              ).insertBefore("#checkout-section");
            } else {
              $(
                '<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' +
                  window.checkoutMessages.unsuccessfulOrder +
                  " " +
                  window.checkoutMessages.requiredFields +
                  "<ul></ul></div>"
              ).insertBefore("#checkout-section");
              for (_i = 0, _len = errors.length; _i < _len; _i++) {
                var value = errors[_i];
                $("#checkout-validation-warning")
                  .find("ul")
                  .append("<li>" + value + "</li>");
              }
            }
            charity.base.scrollTop("#checkout-wrapper");
            $("#complete-purchase-button").attr("disabled", false);
            $("#confirmation-modal").modal("hide");
          },
        });
      });
    }
  },

  submitForm: function (clientSecret) {
    let stripe = Stripe($('meta[name="stripe-key"]').attr("content"), {
      stripeAccount: $('meta[name="stripe-account-id"]').attr("content"),
    });
    const options = {
      clientSecret: clientSecret,
      appearance: {
        theme: "stripe",
      },
      paymentMethodOrder: ["apple_pay", "google_pay", "card"],
    };

    const elements = stripe.elements(options);

    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
    const $form = document.getElementById("process_order");
    const $seralizeForm = $("#process_order");

    $form.addEventListener("submit", async (event) => {
      event.preventDefault();
      await elements.fetchUpdates();
      $.ajax({
        url: "/payments/confirm",
        type: "POST",
        data: $seralizeForm.serialize(),
        dataType: "json",
        success: function async(data) {
          charity.base.disableCloseTabPrompt();
          charity.checkout.confirmStripePayment(
            stripe,
            elements,
            data.charity_id
          );
        },
        error: function (xhr, evt, status) {
          var errors = $.parseJSON(xhr.responseText).errors;
          if (errors.length === 1) {
            $(
              '<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' +
                window.checkoutMessages.unsuccessfulOrder +
                " <b>" +
                errors[0] +
                "</b></div>"
            ).insertBefore("#checkout-section");
          } else {
            $(
              '<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">' +
                window.checkoutMessages.unsuccessfulOrder +
                " " +
                window.checkoutMessages.requiredFields +
                "<ul></ul></div>"
            ).insertBefore("#checkout-section");
            for (_i = 0, _len = errors.length; _i < _len; _i++) {
              var value = errors[_i];
              $("#checkout-validation-warning")
                .find("ul")
                .append("<li>" + value + "</li>");
            }
          }
          charity.base.scrollTop("#checkout-wrapper");
          $("#complete-purchase-button").attr("disabled", false);
          $("#confirmation-modal").modal("hide");
        },
      });
    });
  },

  confirmStripePayment: async function (stripe, elements, charityId) {
    const messageContainer = document.querySelector("#error-message");
    messageContainer.style = "display:none;";

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://charity.giftt.tech/payments/confirmation?charity_id=${charityId}`,
        },
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      messageContainer.style = "display:block;";
      messageContainer.textContent = error.message;
    }
  },

  confirmSubscriptionSubscription: async function (
    stripe,
    subscriptionType,
    clientSecret,
    elements,
    charityId
  ) {
    const messageContainer = document.querySelector(
      "#subscription-error-message"
    );
    messageContainer.style = "display:none;";

    const confirmIntent =
      subscriptionType === "setup"
        ? stripe.confirmSetup
        : stripe.confirmPayment;

    try {
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `https://charity.giftt.tech/payments/confirmation?charity_id=${charityId}`,
        },
      });
      if (error) {
        throw error;
      }
    } catch (error) {
      messageContainer.style = "display:block;";
      messageContainer.textContent = error.message;
    }
  },

  // loadPaymentRequest: function (paymentRequest) {
  //   $("body").on("click", "#complete-purchase-button", function () {
  //     if (window.cardPayment === false) {
  //       paymentRequest.show();
  //       return false;
  //     }
  //   });
  // },

  selectPaymentMethod: function () {
    window.cardPayment = true;
    $("body").on("click", "#payment-methods-wrapper div", function () {
      var paymentMethod = $(this).attr("id");
      $("#payment-methods-wrapper div").removeClass("active");
      $(this).addClass("active");
      if (paymentMethod === "credit-card-button") {
        $("#order_payment_type").val("credit_card");
        window.cardPayment = true;
        $("#card-payment-wrapper").show();
        $("#card-payment-wrapper input, #card-payment-wrapper select").prop(
          "disabled",
          false
        );
      } else if (paymentMethod === "apple-request-button") {
        $("#order_payment_type").val("apple_pay");
        window.cardPayment = false;
        $("#card-payment-wrapper").hide();
        $("#card-payment-wrapper input, #card-payment-wrapper select").prop(
          "disabled",
          true
        );
      } else if (paymentMethod === "google-request-button") {
        $("#order_payment_type").val("google_pay");
        window.cardPayment = false;
        $("#card-payment-wrapper").hide();
        $("#card-payment-wrapper input, #card-payment-wrapper select").prop(
          "disabled",
          true
        );
      }
    });
  },

  hasPaymentMethod: function () {
    return $("#payment-methods-wrapper div.active").length >= 1 ? true : false;
  },

  updateCountryAlpha2: function () {
    $("#billing_address_country_id").change(function () {
      charity.checkout.triggerCountryAlpha2($(this).val());
    });
  },

  triggerGiftAid: function () {
    $("body").on("change", "#giftAid", function () {
      if ($(this).is(":checked")) {
        $("#process_order .gift-aid-text-fields").show();
      } else {
        $("#process_order .gift-aid-text-fields").hide();
      }
    });

    $("body").on("change", "#subscriptionGiftAid", function () {
      if ($(this).is(":checked")) {
        $("#process_subscription .gift-aid-text-fields").show();
      } else {
        $("#process_subscription .gift-aid-text-fields").hide();
      }
    });
  },

  triggerCountryAlpha2: function (val) {
    $("#complete-purchase-button").attr("disabled", true);
    $.ajax({
      url: "/countries/" + val,
      type: "GET",
      dataType: "json",
      success: function (data) {
        $("#country_alpha_2").val(data.alpha_2);
        $("#complete-purchase-button").attr("disabled", false);
      },
      error: function (xhr, evt, status) {
        $("#complete-purchase-button").attr("disabled", false);
      },
    });
  },

  validCheckout: function () {
    window.setInterval(function () {
      var supporterData = $("#checkout-wrapper").attr("data-supporter-data");
      if (supporterData === "enabled") {
        if (
          charity.base.inputHasValue($("#process_order #user_email")) &&
          charity.base.inputHasValue($("#process_order #user_first_name")) &&
          charity.base.inputHasValue($("#process_order  #user_last_name")) &&
          $("#checkoutTerms").is(":checked")
        ) {
          $("#complete-purchase-button").removeAttr("disabled");
        } else {
          $("#complete-purchase-button").attr("disabled", "true");
        }
      } else {
        if ($("#checkoutTerms").is(":checked")) {
          $("#complete-purchase-button").removeAttr("disabled");
        } else {
          $("#complete-purchase-button").attr("disabled", "true");
        }
      }
    }, 1000);
  },

  validSubscripionCheckout: function () {
    window.setInterval(function () {
      var supporterData = $("#checkout-wrapper").attr("data-supporter-data");
      if (supporterData === "enabled") {
        if (
          charity.base.inputHasValue($("#process_subscription #user_email")) &&
          charity.base.inputHasValue(
            $("#process_subscription #user_first_name")
          ) &&
          charity.base.inputHasValue(
            $("#process_subscription #user_last_name")
          ) &&
          $("#subscriptionCheckoutTerms").is(":checked")
        ) {
          $("#complete-subscription-button").removeAttr("disabled");
        } else {
          $("#complete-subscription-button").attr("disabled", "true");
        }
      } else {
        if ($("#subscriptionCheckoutTerms").is(":checked")) {
          $("#complete-subscription-button").removeAttr("disabled");
        } else {
          $("#complete-subscription-button").attr("disabled", "true");
        }
      }
    }, 1000);
  },

  selectTipAmount: function () {
    $("body").on("click", ".checkout-select-tip", function () {
      $("input#order_gross").val($(this).attr("data-value"));
      charity.checkout.updatePaymentIntent();
      return false;
    });
  },

  selectTipPlan: function () {
    $("body").on("click", ".checkout-select-plan", function () {
      var planPrice = $(this).attr("data-value-singularize");
      charity.checkout.updateElementAmount(planPrice);
      return false;
    });
  },

  loadCustomCurrency: function (customCurrency) {
    var charityToken = $("#checkout-wrapper").attr("data-charity-token"),
      eventToken = $("#checkout-wrapper").attr("data-event-token"),
      customCountry = $("#custom-currency-select").val();
    $.ajax({
      url:
        "/payments/custom_currency?custom_currency=" +
        customCurrency +
        "&id=" +
        charityToken +
        "&event_token=" +
        eventToken +
        "&custom_country=" +
        customCountry,
      type: "GET",
      dataType: "json",
      success: function (data) {
        $("#checkout-donation-prices").html(data.donation_prices);
      },
      error: function (xhr, evt, status) {},
    });
  },

  customCurrencySelect: function () {
    $("#custom-currency-select").on("change", function () {
      charity.checkout.loadCustomCurrency($(this).val());
      $("#custom_country").val($(this).val());
    });
  },

  customLanguageSelect: function () {
    $("#custom-language-select").on("change", function () {
      var charityToken = $("#checkout-wrapper").attr("data-charity-token"),
        eventToken = $("#checkout-wrapper").attr("data-event-token"),
        customCurrency = $("#custom-currency-select").val();

      charity.base.disableCloseTabPrompt();
      window.location.href =
        "/payments/" +
        charityToken +
        "/checkout?event_token=" +
        eventToken +
        "&locale=" +
        $(this).val() +
        "&custom_currency=" +
        customCurrency;
    });
  },
};
