charity.customer = {

    createCsvReport: function () {
        $('#export-customer-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var customerIds = $('#customers-index').attr('data-customer-ids').split(',');
            if (customerIds.length === 0) {
                charity.base.addFlash("warning csv-report-flash", "You need to have some customers before requesting a CSV report.");
                charity.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/customer_csvs',
                        data: {'customer_ids': customerIds},
                        type: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            $('#charity-flashes').prepend('<div class="alert alert-success csv-report-flash" role="alert">Successfully created the CSV report. Please check your current email account for a link to the file.</div>')
                            charity.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            $('#charity-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">Unable to create the CSV report. Please make sure you have customers with marketing optin enabled.</div>');
                            charity.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },
}