charity.donation = {
    addPrice: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#edit-charity-prices').append(content.replace(regexp, new_id));
        $('#charity_donation_prices_attributes_' + new_id + '_price').focus();
    },

    removePrice: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },
}