charity.base = {
    jsonErrors: function (xhr, evt, status, form) {
        var content, value, _i, _len, _ref, $this, contentWrapper;
        $this = form;
        contentWrapper = $this.find('.json-error-wrapper');
        content = contentWrapper.find('#errors');
        content.find('ul').empty();
        _ref = $.parseJSON(xhr.responseText).errors;
        // Append errors to list on page
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            value = _ref[_i];
            content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
        }
        contentWrapper.show();
    },

    mdbootstrap: function () {
        $('.mdb-select').materialSelect();
        $(".button-collapse").sideNav();
        $('.field_with_errors + label').addClass('active');
        $('.mdb-select').click(e => e.stopPropagation());

        $('.table-responsive').on('shown.bs.dropdown', function (e) {
            var t = $(this),
                m = $(e.target).find('.dropdown-menu'),
                tb = t.offset().top + t.height(),
                mb = m.offset().top + m.outerHeight(true),
                d = 20; // Space for shadow + scrollbar.
            if (t[0].scrollWidth > t.innerWidth()) {
                if (mb + d > tb) {
                    t.css('padding-bottom', ((mb + d) - tb));
                }
            } else {
                t.css('overflow', 'visible');
            }
        }).on('hidden.bs.dropdown', function () {
            $(this).css({
                'padding-bottom': '',
                'overflow': ''
            });
        });

        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
            $('.dropdown-toggle').dropdown();
        })
    },

    enableCloseTabPrompt: function () {
        window.onbeforeunload = function (e) {
            e = e || window.event;

            // For IE and Firefox prior to version 4
            if (e) {
                e.returnValue = 'Have you forgotten something? Your order is waiting for you!';
            }

            // For Safari
            return 'Have you forgotten something? Your order is waiting for you!';
        }
    },

    disableCloseTabPrompt: function () {
        window.onbeforeunload = null;
    },

    addFlash: function (alertClass, message) {
        $('#charity-wrapper').prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
    },

    scrollTop: function (element) {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(element).offset().top
        }, 1000);
    },

    inputHasValue: function (element) {
        return element.length > 0 && element.val() !== '' ? true : false
    },

    updateQueryStringParameter: function (uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            return uri + separator + key + "=" + value;
        }
    },

    changeSearchLimitParam: function () {
        $('#limit_param').on('change', function () {
            window.location = charity.base.updateQueryStringParameter(window.location.href, 'per_page', this.value);
            $('#search_limit').val(this.value);
        });
    },

    newWYSIWYG: function (element_id) {
        $(element_id).mdbWYSIWYG();
    }
}