charity.event = {
    addDonationPrice: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#edit-event-prices').append(content.replace(regexp, new_id));
        $('#event_donation_prices_attributes_' + new_id + '_price').focus();
    },

    removeDonationPrice: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },

    createCsvReport: function () {
        $('#export-event-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var eventIds = $('#events-index').attr('data-event-ids').split(',');
            if (eventIds.length === 0) {
                charity.base.addFlash("warning csv-report-flash", "You need to have some events before requesting a CSV report.");
                charity.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/event_csvs',
                        data: {'event_ids': eventIds},
                        type: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            $('#charity-flashes').prepend('<div class="alert alert-success csv-report-flash" role="alert">Successfully created the CSV report. Please check your current email account for a link to the file.</div>')
                            charity.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            $('#charity-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">Unable to create the CSV report. Please make sure you have events.</div>');
                            charity.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    }
}