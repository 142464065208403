charity.onboarding = {
  createCharity: function () {
    $("body").on("submit", "#create-charity-form", function () {
      var $this = $(this),
        url = $this.attr("action");

      $.ajax({
        url: url,
        type: "POST",
        data: $(this).serialize(),
        dataType: "json",
        success: function (data) {
          $this[0].submit();
        },
        error: function (xhr, evt, status) {
          charity.base.jsonErrors(xhr, evt, status, $this);
          charity.base.scrollTop("main");
          $(".modal-backdrop").show();
        },
      });
      return false;
    });
  },

  submitForm: function (clientSecret) {
    let stripe = Stripe($('meta[name="stripe-key"]').attr("content"));
    const options = {
      clientSecret: clientSecret,
      appearance: {
        theme: "stripe",
      },
    };

    const elements = stripe.elements(options);

    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
    const $form = document.getElementById("process-onboarding-charity-sub");

    $form.addEventListener("submit", async (event) => {
      event.preventDefault();
      const messageContainer = document.querySelector("#error-message");
      messageContainer.style = "display:none;";

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url:
            "https://charity.giftt.tech/onboarding/payment/confirmation",
        },
      });

      if (error) {
        messageContainer.style = "display:block;";
        messageContainer.textContent = error.message;
      }
      return false;
    });
  },

  businessTypeSelect: function () {
    $("body").on("change", "#charity_business_type", function () {
      const businessTypeValue = $(this).val();
      switch (businessTypeValue) {
        case "individual": {
          $("#custom-charity-name").show();
          $("#charity-name").hide().attr("disabled", true);
          $("#charity-url").hide();
          $("#charity-activity").show();
          $("#charity-registered-number").hide();
          $("#charity-description label").text(
            "Tell us about your fundraising activity"
          );
          $("#registered-business-address").hide();
          $("#bank-account-details").hide();
          $("#identity-document").hide();
          $("#charity-name-label").text(
            "Choose a cause you would like to support"
          );
          break;
        }
        case "business": {
          $("#custom-charity-name").show();
          $("#charity-name").hide().attr("disabled", true);
          $("#charity-url").hide();
          $("#charity-activity").hide();
          $("#charity-registered-number").hide();
          $("#charity-description label").text(
            "Tell us about your fundraising activity"
          );
          $("#registered-business-address").hide();
          $("#bank-account-details").hide();
          $("#identity-document").hide();
          $("#charity-name-label").text(
            "Choose a cause you would like to support"
          );
          break;
        }
        case "charity": {
          $("#custom-charity-name").hide().val("");
          $("#charity-name").show().attr("disabled", false);
          $("#charity-url").show();
          $("#charity-activity").hide();
          $("#charity-registered-number").show();
          $("#charity-description label").text("Tell us about your Charity");
          $("#registered-business-address").show();
          $("#bank-account-details").show();
          $("#identity-document").show();
          $("#charity-name-label").text("Charity Name");
          break;
        }
      }
    });
  },

  fundraisingEventTypeSelect: function () {
    $("body").on("change", "#charity_fundraising_event_name", function () {
      const eventTypeValue = $(this).val();
      switch (eventTypeValue) {
        case "Other": {
          $("#charity-fundraising-event-other").show();
          break;
        }
        default: {
          $("#charity-fundraising-event-other").hide();
        }
      }
    });
  },

  otherCharityCheckbox: function () {
    $("#otherCharity").on("change", function () {
      if ($(this).is(":checked")) {
        $("#custom-other-charity-name").show();
      } else {
        $("#custom-other-charity-name").hide();
        $('#custom-other-charity-name input[type="text"]').val("");
      }
    });
  },
};
